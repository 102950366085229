import { BehaviorSubject } from "rxjs";

import { HandleResponse } from "../_helpers";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const AuthenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(identification, password) {
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ identification, password }),
  };

  //return fetch(`https://emprendimientosvirtuales.com.py/s1_cerro/public/admin/login/singing`, requestOptions)
  //return fetch(`https://local.quattropy.com/emprendimiento/s1/public/admin/login/singing`, requestOptions)
  //return fetch(`https://sospy.org/s1_calautos/public/admin/login/singing`, requestOptions)
  //return fetch(`https://votacandidatos.sospy.org/s1/public/admin/login/singing`, requestOptions)
  //return fetch(`https://sospy.org/s1_cerro/public/admin/login/singing`, requestOptions)
  //return fetch(`https://sospy.org/s1_guairena/public/admin/login/singing`, requestOptions)
  //return fetch(`https://sospy.org/s1_prode/public/admin/login/singing`, requestOptions)
  return fetch(`https://sospy.org/s1_luque/public/admin/login/singing`, requestOptions)
  //return fetch(`https://sospy.org/s1_cooperativas/public/admin/login/singing`, requestOptions)

    .then(HandleResponse)
   
    .then((user) => {
      
      if(user.status == "success"){
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
      }
       if (!user.status == "success"){ 
        return user.message;

      }
      
     console.log("dta",user)
    });
  
}

export function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  window.location.replace("/");
}
